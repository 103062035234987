h1 {
  text-align: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container video,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#bible-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin: auto; */
  gap: 16px;
}

@media only screen and (min-width: 1050px) {

  .video-container video,
  .video-container object,
  .video-container embed {
    position: static;
    top: 0;
    left: 0;
    width: 560px;
    height: 315px;
  }

  .video-container {
    padding: initial;
    height: auto;
    overflow: hidden;
    margin: auto;
  }

  #bible-form {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin: auto;
    gap: 16px;
  }
}


.video-and-title {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

#confirm-submit {
  color: green;
  text-align: center;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}